import logo from './logo.svg';
import './App.css';
import { useState,useEffect } from "react";
import React, { useRef } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import MenuIcon from '@mui/icons-material/Menu';
import Body from './Component/Body.js';
import Project from "./Component/Project.js"
import About from "./Component/About.js";
import Foot from "./Component/Foot.js";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { showConfirm } from "react-confirm-prompt";

function App() {
  const [isOpen, setIsOpen] = useState(false)
  
  const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    const Home = useRef(null);
    const about = useRef(null);
    const contact = useRef (null);
    const project = useRef (null);
const scrollToSection = (elementRef) => {
  setIsOpen(false);
window.scrollTo({
top: elementRef.current.offsetTop,
behavior: "smooth",
});
};
const metaTag = document.querySelector('meta[name="theme-color"]');


  const statusbared = () => {
    document.getElementById('idi').style.background = "white"
    document.getElementById('idi').style.color = 'black'
    document.getElementById('head').style.background = '#d13939'
    document.getElementById('btn-body').style.background = 'red'
    document.getElementById('btn-body').style.color = 'white'
    metaTag.setAttribute("content", "#d13939");
  }
  
  function handleShowConfirm() {
    showConfirm("This website is not build for Desktop , Are you sure to visit?").then((answer) => {
      if(answer) {
        
      } else {
        
      }
    });
  }
  
useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      
    } else {
      handleShowConfirm();
    }
  }, []);

  return (
    <div className="App" >
            <Drawer id ="dr" style={{background:"black",overflow:"hidden",width:"90%"}}
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className='bla bla bla'
            >            <div className="drawer">
                <div className="dr-menu-txt">
                   
                 </div>
                      <div  onClick={() => scrollToSection (Home)} className="dr-menu-list">

                                 <HomeOutlinedIcon htmlColor="#ffffff" sx={{ fontSize: 25 }}/>    <div className="dr-about" >Home</div>
                 </div>
                                  <div  onClick={() => scrollToSection (project)} className="dr-menu-list">
<KeyboardDoubleArrowRightOutlinedIcon htmlColor="#ffffff" sx={{ fontSize: 25 }}/>
                             <div className="dr-contact">Project</div>
                 </div>          
                 <div onClick={() => scrollToSection (about)} className="dr-menu-list">
<WindowOutlinedIcon htmlColor="#ffffff" sx={{ fontSize: 25 }}/>
                                     <div className="dr-about" >About</div>
                 </div>
                 <div onClick={() => scrollToSection (contact)} className="dr-menu-list">
<ForumOutlinedIcon htmlColor="#ffffff" sx={{ fontSize: 25 }}/>
                             <div className="dr-contact">Contact</div>
                 </div>
          
              
                    
          </div>
            </Drawer>
            
      <header id="head" className="header">
          <div className="head-text">
            <img className="bluetickk" src="Can.png" alt="Logo" />
          </div>
          
          <div className="desk-head">
            <div onClick={() => scrollToSection (Home)} className="opo">Home </div>
            <div onClick={() => scrollToSection (about)} className="opo">About</div>
            <div onClick={() => scrollToSection (project)} className="opo">Project</div>
          </div>
          
        <div onClick={toggleDrawer} className="drawee-icon"><MenuIcon htmlColor="#ffffff" sx={{ fontSize: 50 }}/></div>

      </header>
         <div id="idi" className="Home" ref={Home} >
           <Body/>
                </div>
         <div className="project" ref={about}>
              <Project/>
                </div>
         <div className="about" ref={project}>
           <About/>
                </div>
         <div className="contact" ref={contact}>
           <Foot/>
                </div>
    </div>
  );
}

export default App;
