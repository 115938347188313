import './Project.css';



function Project() {
  
  
  
    
  
  return (
    <div>
    <div className="Project">
      <div>
    <img className="image-pro" src="Mo.png" alt="Logo" />
      </div>
   <div className="txt-project">
   <div>About</div>   <div className="txt-project1"> Me</div>
   </div>
   
   <div className="txt-project2">I am a Developer and Designer<br/> based in jhapa, Nepal 🌍. I have<br/> always been fascinated by<br/> Knowledge 🧠 and learning<br/> new things. Throughout my life, I<br/> have spoken, read and written <br/>in English fluently. Currently, I'm<br/> exploring Restful API's 👩🏾‍💻 as part <br/>of my skillset. When not working<br/> on projects or spending time <br/>with family or friends, you can<br/> usually find me watching<br/> YouTube videos 💻 or listening<br/> to music 🎧. As an experienced <br/>Web developer who is skilled in<br/> many areas of development 😎<br/> (responsive design being one),I<br/> would love to work with<br/> businesses both small and<br/> large!</div>
   
   
      </div>
      <div className="txt-4">
   <div className="txt-project3">
     <div className="txt-project4">Age : </div>
     <div>   20</div>

    </div>
       <div className="txt-project3">
     <div className="txt-project4">Gander : </div>
     <div>   Male</div>

    </div>
   <div className="txt-project3">
     <div className="txt-project4">Languages : </div>
     <div>   Nepali/English/Tajpuriya</div>

    </div>
       <div className="txt-project3">
     <div className="txt-project4">Email : </div>
     <div>er.jibantajpuriya@gmail.com</div>

    </div>
   <div className="txt-project3">
     <div className="txt-project4">Address : </div>
     <div>Shivgunj/Jhapa</div>

    </div>
    </div>
    </div>
      );
}

export default Project;