import './Body.css';
import React, { useEffect, useState } from 'react';
import Particles from 'react-tsparticles';
import ParticlesComponent from "./T.js";

function Body() {
const [stars, setStars] = useState([]);

  useEffect(() => {
    const starCount = 100;
    const newStars = Array.from({ length: starCount }, () => ({
      id: Math.random(),
      left: Math.random() * 100,
      animationDuration: Math.random() * 3 + 2,
    }));
    setStars(newStars);
  }, []);

    
  
  return (
    <>
    <ParticlesComponent className="tx"/>
    <div  style={{ position: 'absolute'}} className="bodyy">
      <div>
      <div className="profile">
        <img className="profile-pic" src="J.jpg" alt="jiban tajpuriya| jeewan tajpuriya| jiban| jeewan" />
      </div>
       <div className="profile-txt">
        <h1 className="profile-txt3">Jiban Tajpuriya</h1>
        <div ><img className="bluetick" src="Blue.png" alt="Logo" /></div>
      </div>
      </div>
      <h2 className="body-txt">
        Hi 👋 there, I am Jiban Tajpuriya <br/> I am From Gaindhari ,Jhapa, Nepal. <br/>I am a Software Developer 🖥️🚀💻 <br/> I have Developed Many Software & Website Which is <br/>Work Fast and More Reliable.
              <div id="btn-body"  className="button">
        Explore
      </div>
      </h2>

    </div>
        
        </>
      );
}

export default Body;