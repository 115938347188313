import "./Foot.css";


function Foot(){
 
  
  return (

<div className="main">
    <div className="heading">
    Jeewan99 | Portfolio
    </div>
    <div className="discription">Need any help with your projects? contact me at the places mentioned below. I will try to get back to you as fast as I can.

Follow me for more such Projects.</div>


            <img className="nnnnnnnnnn" src="1.jpg" alt="Logo" />
            <img className="nnnnnnnnnn" src="2.jpg" alt="Logo" />
            
          <img className="nnnnnnnnnn" src="3.jpg" alt="Logo" />
                           <img className="nnnnnnnnnn" src="4.jpg" alt="Logo" />
<div className="center"><a href="https://github.com/Jeewan99" className="btn-ab">
     <img className="image-star" src="s.png" alt="Logo" /> Star me on github</a></div>
<div className="heading">Quick Links</div>
<ul>
  <li>Home</li>
    <li>About myself</li>
        <li>Project</li>
            <li>Skill</li>
                <li>Education</li>
</ul>
<div className="heading"> Contact Info</div>
<ul>
  <li>er.jeewantaj@gmail.com</li>

</ul>
<div className="fot"><div>Made with ❤️ by jiban</div></div>
    </div>











  );
}
export default Foot;