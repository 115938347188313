import "./About.css";

function About(){
  
  return(
    <>
      <div className="top">
                  </div>

     <div className="atxt-1">
      Projects
     <div className="atxt-2">&</div>

    </div>
    <div className="atxt-3">
      Experiences
    </div>

<div className="atxt-4">
  You can find all of my projects on my profile on Github. You can view my activities, contributions and code on these projects. Some of these are completed and some are in progress. You can also leave comments on the profile and start a discussion. I am always looking for new projects and Ideas. You can also reach out to me on any of the social media channels mentioned below.
</div>
<div className="center"><a href="https://github.com/Jeewan99" className="btn-ab">
          <img className="image-star" src="s.png" alt="Logo" />
   Star me on github </a></div>

<div className="centerr">
<div className="card" data-aos="slide-up">
  <div className="image">
        <img className="image-abo" src="pro.jpg" alt="Logo" />
  </div>
  
   <div className="txta">
  <div className="fn-12">HyperLogin App</div>  <div>Nov<br/> 2022</div>
   </div>
  <div className="btna">
      <div className="btnaa">project Code</div>  <div className="btnaa">Live project</div>
  </div>
</div>

<div className="card" data-aos="slide-up">
  <div className="image">
        <img className="image-abo" src="loo.png" alt="Logo" />
  </div>
  
   <div className="txta">
  <div className="fn-12">Open-metacryptoAI
</div>  <div>Feb<br/> 2023</div>
   </div>
  <div className="btna">
      <div className="btnaa">project Code</div>  <div className="btnaa">Live project</div>
  </div>
</div>


<div className="card" data-aos="slide-up">
  <div className="image">
        <img className="image-abo" src="lo.jpg" alt="Logo" />
  </div>
  
   <div className="txta">
  <div className="fn-12">Dental-Clinic Web-Application</div>  <div>Nov<br/> 2022</div>
   </div>
  <div className="btna">
      <div className="btnaa">project Code</div>  <div className="btnaa">Live project</div>
  </div>
</div>
</div>
        </>
    );
}
export default About;